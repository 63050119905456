html, body {
  margin: 0;
  font-family: 'Exo', Arial, sans-serif;
}

.container {
  padding: 1.5rem;
}

.main-container {
  padding: 0;

  .main-container-row,
  .main-container-col {
    margin: 0;
    padding: 0;
  }
}
