.paylog-homepage {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
  }

  .logo-container {
    padding: 1rem 0;
    display: flex;
    align-items: center;

    .app-name {
      font-size: 1.8rem;
      margin: 0 0 0 .3rem;
      font-weight: bold;
    }

    .logo {
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  .email-container {
    display: flex;
    align-items: center;
    position: relative;
    border-bottom: 2px solid #343a40;
    padding-bottom: 1px;

    &:hover {
      color: #b1b1b1;
      border-bottom: 2px solid #b1b1b1;

      a {
        color: #b1b1b1;
      }
    }

    .email-icon {
      margin-right: .3rem;
    }

    a {
      color: white;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .content-container {
    flex: 1 1 0;
    display: flex;
    align-items: center;

    h1 {
      font-size: 4rem;
    }
  }

  .socials-container {
    margin: 3rem 0;
  }

  .socials {
    display: flex;

    .social {
      margin-right: 1rem;
      color: white;
      padding: .8rem;
      border: 2px solid white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        border: 2px solid #b1b1b1;
        color: #b1b1b1;
      }

      &.email {
        display: none;
      }
    }

    .social-icon {
      font-size: 1.2rem;
    }
  }

  .mailing-container {
    .mailing-form {
      display: flex;

      .mailing-email {
        border: none;
        margin-right: .5rem;

        &:focus {
          outline: none;
          border: none;
        }
      }

      .mailing-submit {
        min-width: 80px;
      }

      .loading {
        display: block;
        -webkit-animation: spin 1.4s infinite linear;
        animation: spin 1.4s infinite linear;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    font-size: 1rem;

    .copyright {
      margin: 1rem 0;
    }
  }

  @media (max-width: 575px) {
    .logo-container {
      padding: 0;
    }

    .email-container {
      display: none;
    }

    .content-container {
      margin: 2rem 0;

      .content {
        width: 100%;
      }

      h1 {
        font-size: 3rem;
      }
    }

    .socials-container {
      .social {
        &.email {
          display: flex;
        }
      }
    }

    .mailing-form {
      flex-direction: column;

      .mailing-email {
        margin-right: 0;
      }

      .mailing-submit {
        margin-top: .5rem
      }
    }

    .footer {
      .copyright {
        margin: 0;
      }
    }
  }

  @media (max-height: 600px) {
    height: 100%;
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

